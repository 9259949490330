export default `<div>
    <p class="img"><img src="/storage/app/media/uploaded-files/WeChat%20Image_20221011192755.jpg" style="width: 300px;" class="fr-fic fr-dib" data-result="success"></p>
    
    
    <strong>Clinically proven technology in the tricuspid valve replacement device market</strong>
    
    <p>Jenscare is a medical device company dedicated to the development of innovative solutions for structural heart disease. They have developed a range of innovative products for various types of structural heart disease, including tricuspid valve diseases, mitral valve disease, aortic valve disease, and heart failure. The company currently has ten products in development, covering a broad product range for all types of structural heart disease. Its core product, LuX-Valve, has the potential to treat severe tricuspid regurgitation, and is the world's first product in development to complete enrollment of subjects in a confirmatory clinical trial. This makes it one of the first tricuspid valve replacement products to be approved for commercialization worldwide, and possibly breaks the history of no approved products worldwide. As an innovative medical device manufacturer, Jenscare has always focused on developing technological leadership in its niche through a comprehensive IP portfolio, of which LuX-Valve alone holds 13 domestic and international patents for inventions related to it.</p>

    <p>In addition to LuX-Valve, Jenscare is developing two other transcatheter tricuspid valve intervention devices, LuX-Valve Plus and JensT-Clip, of which Lux-Valve Plus was enrolled in a feasibility clinical trial in China in August 2022 and completed a one-month follow-up of the feasibility clinical trial in September 2022. Commercialization of this product is expected to be approved by the National Medical Products Administration in the first half of 2024. In addition, LuX-Valve Plus has been successfully implanted in 11 subjects at St. Paul's Hospital in Canada, with the first European implant in France in July 2022, followed by another in Germany in September 2022, and additional implants in the U.S., France and Spain in the near future. More implantations will be performed in the United States, France and Spain in the near future to benefit patients with tricuspid regurgitation worldwide.</p>
    
    
    <strong>Broad product range for all types of structural heart disease</strong>
    
    <p>Leveraging the company's technological strength in tricuspid valve disease, Jenscare has developed a broad product range targeting a variety of other structural heart diseases, including aortic valve disease, mitral valve disease and heart failure. Currently, the company has developed two transcatheter aortic valve replacement products for the treatment of severe aortic regurgitation (or combined aortic stenosis). According to Frost &amp; Sullivan, based on the current clinical trial progress of all transcatheter aortic valve replacement products in development that include aortic regurgitation as an indication, the company's other core product, Ken-Valve, is expected to be the second transcatheter aortic valve replacement product in China and the third in the world designed for the treatment of severe aortic regurgitation (or combined aortic stenosis), with the product expected to be commercially approved by the National Medical Products Administration in the first half of 2024. In addition to Ken-Valve, the company is developing a next-generation transcatheter aortic valve replacement product, KenFlex, which is expected to be commercially available in the first half of 2025. Among other products in development for the treatment of mitral valve disease and the treatment of heart failure, the company's self-developed transcatheter mitral valve repair clamping system, JensClip, is also the first to enter a feasibility clinical trial in August 2022. The full range of products will enable Jenscare to diversify its revenue streams and integrate its R&amp;D, manufacturing and commercialization activities.</p>

    
    <strong>Focusing on "medical-engineering integration" to truly address unmet clinical needs</strong>
    
    <p>With its strong R&amp;D innovation and "medical-engineering integration" capability, Jenscare has a forward-looking innovative concept and excellent ability to build innovative technical solutions. In the field of aortic valve, the current technology within China largely is, in fact, imitation of other products with certain improvement. However, in the tricuspid valve field, most of the TTVRs currently in clinical trials are designed based on the size of the autologous tricuspid annulus, and the valve fixation relies on the annular support and the clamping of the leaflet structure. This prosthetic valve device in the tricuspid position inevitably leads to complications such as damage to adjacent structures, prosthetic valve fixation instability, and even displacement. Based on this situation, in 2015, according to the structural and functional requirements of the prosthetic tricuspid valve proposed by the clinicians, the R&amp;D team of Jenscare, after repeated attempts, creatively designed an artificial tricuspid valve anchoring device based on the septal fixation method, which is a break through of the traditional design of relying on stent support to fix the valve. In August 2021, the LuX-Valve has been enrolled in a confirmatory clinical trial with 120 subjects and is expected to be commercialized by the National Medical Products Administration in the first half of 2023. Approval is expected in the first half of 2023.</p>
    
    <strong>A comprehensive platform capable of turning strategic concepts into real products in development</strong>
    
    <p>Jenscare has established a full cycle R&amp;D function from medical needs identification to product development to commercialization. The R&amp;D team has comprehensive capabilities, including biomaterials, sewing technology, architectural design and processing technology. In addition, the company has established a biology lab to develop innovative anti-calcification animal-derived valve materials and a simulation lab that integrates computer-aided engineering analysis and valve testing. The simulation lab enables the company to conduct in vitro experiments during the process development phase to dissect the mechanisms of products under development. Looking ahead, Jenscare will continue to expand its product range through research and development efforts to enhance its competitive advantage.</p>
    
    <p>Given the stringent manufacturing standards and high quality requirements, the barriers to entry for manufacturing interventional structural cardiac devices are high. For example, for bovine pericardial stents that cannot be automated and rely on manual sewing, the company has employed 25 experienced full-time technicians. In addition, the company has comprehensive manufacturing capabilities, including separate production lines for stents, valves and delivery systems. In line with upcoming product launches, Jenscare has expanded its annual production capacity to approximately 4,000 to 5,000 units in 2021 and expects to continue to expand its annual production capacity to approximately 10,000 units by the end of 2024. It is believed that the company's manufacturing capabilities will provide advantages for clinical trials and future commercialization</p>
    
    <strong>Rapid penetration into China's hospitals with the support of industry opinion leaders, key researchers and hospitals</strong>
    
    <p>Jenscare adopts promotion in academia to build its brand awareness and market awareness of its products under development. The company will continue to sponsor industry-leading academic conferences. Through frequent participation in academic conferences and close interaction with physicians and hospitals, the company has cultivated long-term relationships in this area. Jenscare also engages key industry opinion leaders in product design and clinical trials to increase product awareness and confidence in its products. As of September 13, 2022, the Company has conducted clinical trials in over 20 major hospitals in China.</p>
    
    <p>Looking ahead, Jenscare strives to become a leading global medical device platform, offering a comprehensive range of innovative products for the treatment of structural heart disease. Jenscare plans to accelerate the development and commercialization of its products to further strengthen its market positioning; focusing on structural heart disease,Jenscare intends to develop a diversified and robust product range for heart valve disease and heart failure. In addition to continuing to develop pre-clinical products, the company will continue to identify strategic opportunities to develop new devices with significant clinical benefit and market potential. In the long term, the company expects to add one to two products in development to its product range each year. In addition, Jenscare plans to build on its product development capabilities and seek strategic partnerships to expand its product portfolio and expand its international coverage to become an industry leader.</p>

    <p>Dr. Edward Huang, Managing Partner of FutureX, says, "The LuX-Valve Plus transvascular tricuspid valve replacement(TTVR) system is one of the most advanced medical devices exported from China to Europe and the United States, and has successfully performed minimally invasive tricuspid valve replacement surgery for over 300 patients with tricuspid regurgitation worldwide. The LuX-Valve Plus has been highly recognized in China and in North America and Europe, with the post-operative ultrasound showed satisfactory fixation of the prosthetic tricuspid valve – stable frame, no regurgitation and no perivalvular leakage. Patients were extubated immediately in the operating room with significant hemodynamic improvement. The LuX-Valve Plus TTVR system is truly a world-leading and cost effective treatment, and we look forward to the continuous product innovation in the global structural cardiology field from Jenscare, which will continue to provide new products to benefit patients worldwide and allow cardiac surgeons worldwide to recognize the strength of China's own innovative devices."</p>
  </div>`
